'use strict';

angular.module("aerosApp")
    .factory('createFiberGroupModal', ["$uibModal", "aerosApi", function ($uibModal, aerosApi) {
        function open(fiberType) {
            var modal = $uibModal.open({
                size: "md",
                templateUrl: "/static/templates/dashboard/project/modal/addEditFiberGroupModal.html",
                controller: "CreateFiberGroupModalCtrl",
                resolve: {
                    fiberType: function () {
                        return fiberType;
                    },
                    fiberTypeStructure: function () {
                        return aerosApi.getFiberTypes()
                            .then(function (results) {
                                return results.data;
                            });
                    },
                    config: ["configService", function (configService) {
                        return configService.getPromise();
                    }],
                    org: ["aerosApi", "$rootScope", function (aerosApi, $rootScope) {
                        return aerosApi.loadOrganization($rootScope.orgId)
                            .then(function (response) {
                                return response.data;
                            });
                    }]
                }
            });

            return modal.result;
        }

        return {
            open: open
        };
    }])
    .controller("CreateFiberGroupModalCtrl", ["$scope", "$uibModalInstance", "$uibModal", "config", "fiberTypes",
        "fiberTypeStructure", "fiberType", "$translate", "FiberGroupService", "org", "UserProfileService",
        "UnitsMeasurementService",
        function ($scope, $uibModalInstance, $uibModal, config, fiberTypes, fiberTypeStructure, fiberType, $translate,
                  FiberGroupService, org, UserProfileService, UnitsMeasurementService) {

            $scope.features = org.info.features;

            $scope.lossDistUnit = UserProfileService.get().user.preferredLossDistanceUnit;
            $scope.converters = UnitsMeasurementService.getConverters('dB/km|' + $scope.lossDistUnit);

            if ($scope.converters.showUnitConverter) {
                $scope.convert = $scope.converters.showUnitConverter;
            } else {
                $scope.convert = function (value) {
                    return value;
                };
            }

            $scope.updateCableFiberByNetworkType = updateCableFiberByNetworkType;

            $scope.formAction = {
                edit: false,
                add: true
            };

            $scope.networkTypes = config.getNetworkTypes();
            $scope.fiberTypes = fiberTypes;
            $scope.subFiberTypes = getFiberSubTypes(fiberTypeStructure);
            $scope.waveLengthsBySubFiberType = fiberTypeStructure;
            $scope.fiberGroupService = FiberGroupService;

            $scope.connectors = config.getConnectorTypes();
            $scope.links = config.getLinkTypes();
            $scope.mpos = config.getMpoTypes();

            $scope.ui = {};
            $scope.ui.showMore = false;

            $scope.fiberGroup = {
                name: "",
                multipleName: "",
                fiberType: fiberType || $scope.fiberTypes[0].id,
                fiberCount: 12,
                startFiber: 1,
                connectionCount: 2,
                spliceCount: 0,
                end1Name: "End1",
                end1Connector: {
                    connectorType: "None",
                    linkType: "None"
                },
                end2Name: "End2",
                end2Connector: {
                    connectorType: "None",
                    linkType: "None"
                },
                mpoPolarity: "None",
                comment: "",
                helixFactor: 1,
                fibers: []
            };

            defaultsNetworkCableFiber(fiberType, fiberTypes);

            $scope.selectedLinks = {
                end1: new Array($scope.links.length),
                end2: new Array($scope.links.length)
            };
            $scope.setLink = function (end, link) {
                if ($scope.selectedLinks[end][link]) {
                    $scope.fiberGroup[end + "Connector"].linkType = $scope.links[link].value;
                    // only allow one link type per end to be checked
                    $scope.selectedLinks[end] = _.map($scope.selectedLinks[end], function (linkSelected, i) {
                        return i === link;

                    });
                } else {
                    // set linkType to "None" when checkbox is de-selected
                    $scope.fiberGroup[end + "Connector"].linkType = "None";
                }
            };

            // hide & reset linkType when no connectorType for end
            $scope.endHasConnectorSelected = function (end) {
                var connectorObj = $scope.fiberGroup[end + "Connector"];
                var endConnector = (connectorObj.connectorType !== "None");
                if (!endConnector) {
                    connectorObj.linkType = "None";
                }
                return endConnector;
            };

            // TODO: hard-coded logic based on last-minute requirements. . . should be more "plug-able", less brittle
            var mTypes = ["MPO_12F", "MPO_APC_12F"];

            function hideMapper() {
                $scope.showMapper = false;
                $scope.fiberGroup.mpoPolarity = "None";
                $scope.availableMpo = [];
            }

            function showMapper(mpoList) {
                $scope.showMapper = true;
                $scope.fiberGroup.mpoPolarity = (_.find(mpoList, {value: origMpoSelected}) ? origMpoSelected : mpoList[0].value);
                $scope.availableMpo = mpoList;
            }

            var ALL_OPTIONS = [
                {value: "A", label: "Polarity A"},
                {value: "B", label: "Polarity B"},
                {value: "C", label: "Polarity C"},
                {value: "Base8", label: "Base 8"}
                // AEROS-1616 hide "custom" mapper until we can support
                //{value: "Custom", label: "Custom"}
            ];
            var ALL_BUT_EIGHT = [
                {value: "A", label: "Polarity A"},
                {value: "B", label: "Polarity B"},
                {value: "C", label: "Polarity C"}
                // AEROS-1616 hide "custom" mapper until we can support
                //{value: "Custom", label: "Custom"}
            ];
            var CUSTOM_ONLY = [
                //AEROS-1616 hide "custom" mapper until we can support
                //{value: "Custom", label: "Custom"}

                //AEROS-1627 one M + one S shows "Polarity A"
                {value: "A", label: "Polarity A"}
            ];
            var origMpoSelected;
            $scope.checkMapper = function () {
                if ($scope.fiberGroup.mpoPolarity !== "None") {
                    origMpoSelected = angular.copy($scope.fiberGroup.mpoPolarity);
                }
                var connectors = [$scope.fiberGroup.end1Connector.connectorType, $scope.fiberGroup.end2Connector.connectorType];
                // one of connectors is MPO_something
                if (mTypes.indexOf(connectors[0]) > -1 || mTypes.indexOf(connectors[1]) > -1) {
                    // both connectors are MPO_
                    if (mTypes.indexOf(connectors[0]) > -1 && mTypes.indexOf(connectors[1]) > -1) {
                        if (Number($scope.fiberGroup.fiberCount) === 8) {
                            return showMapper(ALL_OPTIONS);
                        } else {
                            return showMapper(ALL_BUT_EIGHT);
                        }
                        // one is M, other isn't "None" (so single type)
                    } else if (connectors.indexOf("None") === -1) {
                        // AEROS-1616 hide "custom" mapper until we can support
                        // AEROS-1627 one M + one S shows "Polarity A"
                        return showMapper(CUSTOM_ONLY);
                        // else one is M, the other is "None", pass through to hide
                    }
                    // no mType connectors, pass through to hide
                }
                return hideMapper();
            };
            $scope.launchMapper = function () {
                // TODO: tmpl + ctrl should be sep files
                $uibModal.open({
                    size: "md",
                    templateUrl: 'fiberMapper.html',
                    resolve: {
                        availableMpo: function () {
                            return $scope.availableMpo;
                        },
                        selectedMpo: function () {
                            return $scope.fiberGroup.mpoPolarity;
                        },
                        getMapping: function () {
                            return config.getDefaultMapping;
                        },
                        fiberCount: function () {
                            return $scope.fiberGroup.fiberCount;
                        },
                        fibers: function () {
                            return $scope.fibers;
                        }
                    },
                    controller: ["$scope", "$uibModalInstance", "availableMpo", "selectedMpo", "getMapping", "fiberCount",
                        function ($scope, $uibModalInstance, availableMpo, selectedMpo, getMapping, fiberCount) {
                            $scope.availableMpo = availableMpo;
                            $scope.selectedMpo = selectedMpo;
                            $scope.fibers = getMapping(selectedMpo, fiberCount);
                            $scope.updatefibers = function () {
                                $scope.fibers = getMapping($scope.selectedMpo, fiberCount);
                            };
                            $scope.exit = function () {
                                var data = {
                                    selectedMpo: $scope.selectedMpo,
                                    fibers: $scope.fibers
                                };
                                $uibModalInstance.close(data);
                            };
                        }]
                }).result.then(function (data) {
                    $scope.fiberGroup.mpoPolarity = data.selectedMpo;
                    $scope.fiberGroup.fibers = data.fibers;
                });
            };

            $translate('End1').then(function (translation) {
                $scope.fiberGroup.end1Name = translation;
            });
            $translate('End2').then(function (translation) {
                $scope.fiberGroup.end2Name = translation;
            });

            $scope.cancel = function () {
                $uibModalInstance.dismiss();
            };

            $scope.saveFiberGroup = function () {

                var fiberGroupTransformed = angular.copy($scope.fiberGroup);

                fiberGroupTransformed.fiberMode = $scope.fiberGroup.fiberMode.value;
                fiberGroupTransformed.fiberType = $scope.fiberGroup.fiberType.id;

                if ($scope.ui.showMore) {
                    fiberGroupTransformed.name = fiberGroupTransformed.multipleName;
                }
                $uibModalInstance.close(fiberGroupTransformed);
            };

            $scope.showMore = function () {
                $scope.ui.showMore = !$scope.ui.showMore;

                if ($scope.ui.showMore && $scope.fiberGroup.name.length !== 0) {
                    $scope.fiberGroup.multipleName = $scope.fiberGroup.name;
                }
            };

            $scope.checkName = function () {
                if ($scope.fiberGroup.name.length === 0 && $scope.fiberGroup.multipleName.length === 0) {
                    return false;
                }

                if ($scope.ui.showMore) {
                    if ($scope.fiberGroup.multipleName.length !== 0) {
                        var nameArr = $scope.fiberGroup.multipleName.split("\n");
                        for (var i = 0; i < nameArr.length; i++) {
                            if (nameArr[i].length > 60) {
                                return false;
                            }
                        }
                    } else {
                        return false;
                    }
                    return true;
                } else {
                    if ($scope.fiberGroup.name.length === 0) {
                        return false;
                    }
                    return $scope.fiberGroup.name.length <= 60;
                }
            };

            function getFiberSubTypes(fiberTypeStructure) {
                var fTS = fiberTypeStructure,
                    sortedSingleModeArr = _.sortBy(fTS.Singlemode, 'priority'),
                    sortedMultiModeArr = _.sortBy(fTS.Multimode, 'name'),
                    singleModeArrToObj = {},
                    multiModeArrToObj = {};

                sortedSingleModeArr.forEach(function (value) {
                    singleModeArrToObj[value.name] = value;
                });

                sortedMultiModeArr.forEach(function (value) {
                    multiModeArrToObj[value.name] = value;
                });

                fTS.Singlemode = singleModeArrToObj;
                fTS.Multimode = multiModeArrToObj;

                return Object.keys(fTS).reduce(function (a, i) {
                    a[i] = Object.keys(fTS[i]).sort();
                    return a;
                }, {});
            }

            function defaultsNetworkCableFiber(fiberType, fiberTypes) {
                $scope.fiberGroup.fiberType = fiberTypes.find(function (item) {
                    return item.id === fiberType;
                });

                if (!$scope.fiberGroup.fiberType && fiberTypes.length > 0) {
                    $scope.fiberGroup.fiberType = fiberTypes[0];
                }

                if ($scope.fiberGroup.fiberType) {
                    $scope.fiberGroup.fiberMode = $scope.networkTypes.find(function (item) {
                        return item.value === $scope.fiberGroup.fiberType.type;
                    });
                }

                updateCableFiberByNetworkType();
            }

            function updateCableFiberByNetworkType() {
                if (!$scope.fiberGroup.fiberType && fiberTypes.length > 0) {
                    $scope.fiberGroup.fiberType = fiberTypes.filter(function (element) {
                        return element.type === $scope.fiberGroup.fiberMode.value;
                    })[0];
                }

                if ($scope.fiberGroup.fiberMode && $scope.fiberGroup.fiberMode.value === 'Singlemode') {
                    $scope.fiberGroup.subFiberType =
                        $scope.waveLengthsBySubFiberType[$scope.fiberGroup.fiberMode.value]['G.652.C/D'];
                }

                if ($scope.fiberGroup.fiberMode && $scope.fiberGroup.fiberMode.value === 'Multimode') {
                    if ($scope.fiberGroup.fiberType && $scope.fiberGroup.fiberType.id === 'MMF') {
                        $scope.fiberGroup.subFiberType =
                            $scope.waveLengthsBySubFiberType[$scope.fiberGroup.fiberMode.value]['MMF (OM4)']
                    } else {
                        $scope.fiberGroup.subFiberType =
                            $scope.waveLengthsBySubFiberType[$scope.fiberGroup.fiberMode.value][$scope.fiberGroup.fiberType.id];
                    }

                }
            }
        }]);
